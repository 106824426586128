<template>
  <div class="bg-transparent">
    <div class="px-1 -mb-4">
      <IonSearchbar
        placeholder="Buscar función"
        mode="ios"
        v-model="query"
        debounce="250"
      />
    </div>
    <IonMenuToggle>
      <IonCard v-for="(a, b) in menuFinal" :key="b" mode="ios">
        <IonItem lines="none">
          <IonLabel color="medium">
            <h1>{{ a.nombre }}</h1>
          </IonLabel>
        </IonItem>
        <IonList>
          <IonItem v-for="e in a.elementos" :key="e.r" :router-link="e.r">
            <IonIcon slot="start" :icon="e.i" color="medium" />
            <IonLabel color="dark">
              <h2>{{ e.l }}</h2>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonCard>
    </IonMenuToggle>
    <IonRow>
      <IonCol v-if="empresa.telefonoEmergencias">
        <IonButton
          href="tel:4448408994"
          color="danger"
          expand="block"
          fill="outline"
        >
          <IonIcon :icon="call" slot="start" />
          Administración
        </IonButton>
      </IonCol>
    </IonRow>
    <div
      class="px-2"
      v-if="
        ['cliente', 'usuariodelegado'].indexOf(yo.rol) >= 0 &&
        empresa.telefonoEmergencias
      "
    >
      <IonRow>
        <IonCol v-if="empresa.telefonoEmergencias">
          <IonButton
            router-link="/uxcliente/directorio"
            color="danger"
            expand="block"
            fill="outline"
          >
            <IonIcon :icon="call" slot="start" />
            Directorio telefónico
          </IonButton>
        </IonCol>
      </IonRow>
    </div>
    <IonItem @click="salir" lines="none" color="light" class="px-3">
      <IonIcon :icon="logOut" slot="start"></IonIcon>
      <IonLabel>
        <h2>Salir</h2>
      </IonLabel>
    </IonItem>
  </div>
</template>
<script setup>
import {
  IonSearchbar,
  IonMenuToggle,
  IonCard,
  IonItem,
  IonLabel,
  IonIcon,
  IonList,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import { defineProps, computed, ref, onMounted } from "vue";
import Api from "@/components/Api/Api";
const emit = defineEmits(["salir"]);
import {
  cash,
  person,
  car,
  people,
  paw,
  fingerPrint,
  logOut,
  helpBuoy,
  time,
  megaphone,
  document,
  peopleCircle,
  hammer,
  eye,
  home,
  cashOutline,
  logoIonic,
  informationCircle,
  warning,
  bicycle,
  statsChart,
  chatbox,
  build,
  cog,
  swapHorizontal,
  clipboard,
  idCard,
} from "ionicons/icons";
const props = defineProps({
  usuario: {
    type: Object,
    default: function () {
      return {};
    },
  },
  empresa: {
    type: Object,
    default: function () {
      return {};
    },
  },
  coto: {
    type: Object,
    default: function () {
      return {};
    },
  },
});

const menuPorSecciones = [
  {
    nombre: "Mi cuenta",
    roles: ["cliente", "usuariodelegado"],
    elementos: [
      {
        r: "/uxcliente/pagos",
        l: "ESTADO DE CUENTA",
        i: cash,
        ocultar: props.usuario.rol == "usuariodelegado",
      },
      {
        r: "/uxcliente/micuenta",
        l: "Mis datos",
        i: person,
      },
      {
        r: "/uxcliente/vehiculos",
        l: "Mis vehículos",
        i: car,
      },
      {
        r: "/uxcliente/familia",
        l: "Mi familia",
        i: people,
      },
      {
        r: "/uxcliente/mascotas",
        l: "Mis mascotas",
        i: paw,
      },
      {
        r: "/uxcliente/tags",
        l: "Mis TAGs",
        i: idCard,
        ocultar: () => !props.coto?.usarTags,
      },
      {
        r: "/uxcliente/visitantes",
        l: "Visitantes",
        i: fingerPrint,
      },
    ],
  },
  {
    nombre: "Mi condominio",
    roles: ["cliente", "usuariodelegado"],
    elementos: [
      {
        r: "/uxcliente/incidentes",
        l: "Reportar un incidente",
        i: helpBuoy,
      },
      {
        r: "/uxcliente/reservar",
        l: "Reservaciones",
        i: time,
      },
      {
        r: "/uxcliente/entradassalidas",
        l: "Entradas y salidas a cotos",
        i: logoIonic,
      },
    ],
  },
  {
    nombre: "Administrativo",
    roles: ["cliente", "usuariodelegado"],
    elementos: [
      {
        r: "/uxcliente/anuncios",
        l: "Avisos",
        i: megaphone,
      },
      {
        r: "/uxcliente/documentos",
        l: "Documentos del coto",
        i: document,
      },
      {
        r: "/uxcliente/chat",
        l: "Chatear con personal",
        i: chatbox,
        ocultar: !props.coto.usarChat,
      },
    ],
  },
  {
    nombre: "Catálogos",
    roles: ["administrador", "supervisor"],
    elementos: [
      {
        r: "/admin/usuarios",
        l: "Usuarios",
        i: peopleCircle,
      },
      {
        r: "/admin/proveedores",
        l: "Proveedores",
        i: hammer,
      },
      {
        r: "/admin/vigilantes",
        l: "Vigilantes",
        i: eye,
      },
      {
        r: "/admin/cotos",
        l: "Cotos",
        i: home,
      },
    ],
  },
  {
    nombre: "Operaciones Diarias",
    roles: ["administrador", "supervisor"],
    elementos: [
      {
        r: "/admin/servicios",
        l: "Reservaciones",
        i: bicycle,
      },
      {
        r: "/admin/incidentes",
        l: "Incidentes",
        i: informationCircle,
      },
      {
        r: "/admin/pagosmanuales",
        l: "Recepción de pagos",
        i: cashOutline,
      },
      {
        r: "/recepciongastos",
        l: "Recepción de gastos",
        i: statsChart,
      },
    ],
  },
  {
    nombre: "Administración General",
    roles: ["administrador", "supervisor"],
    elementos: [
      {
        r: "/admin/editoredocuenta",
        l: "Estados de cuenta",
        i: cash,
      },
      {
        r: "/admin/empresa",
        l: "Configuración de empresa",
        i: build,
      },
      {
        r: "/admin/admins",
        l: "Usuarios administradores",
        i: cog,
      },
      {
        r: "/admin/anuncios",
        l: "Anuncios para administradores",
        i: megaphone,
      },
      {
        r: "/admin/documentos",
        l: "Documentos para los cotos",
        i: document,
      },
      {
        r: "/admin/gastos",
        l: "Gastos y cotizaciones",
        i: logoIonic,
      },
      {
        r: "/admin/entradassalidas",
        l: "Entradas y salidas",
        i: swapHorizontal,
      },
      {
        r: "/admin/avisos",
        l: "Avisos y encuestas para residentes",
        i: megaphone,
      },
      {
        r: "/admin/visitantes",
        l: "Pases de visitante",
        i: fingerPrint,
      },
      {
        r: "/admin/amonestaciones",
        l: "Multas de residentes",
        i: warning,
      },
      {
        r: "/admin/notificaciones",
        l: "Crear notificaciones",
        i: megaphone,
      },
      {
        r: "/uxcliente/servicios",
        l: "Notificar recorridos de proveedores",
        i: megaphone,
      },
      {
        r: "/admin/intereses",
        l: "Crear cortes de intereses",
        i: cash,
      },
      {
        r: "/admin/bitacora",
        l: "Bitácora de eventos",
        i: clipboard,
      },
    ].sort((a, b) => a.l.localeCompare(b.l)),
  },
  {
    nombre: "Vigilancia",
    roles: ["vigilante"],
    elementos: [
      {
        r: "/uxcliente/es",
        l: "Entradas y salidas",
        i: logOut,
      },
      {
        r: "/uxcliente/visitantes",
        l: "Visitantes",
        i: eye,
      },
      {
        r: "/uxcliente/incidentes",
        l: "Incidentes",
        i: informationCircle,
      },
      {
        r: "/uxcliente/servicios",
        l: "Avisar de servicios",
        i: megaphone,
      },
    ],
  },
];
let query = ref("");
const yo = computed(() => {
  return (
    props.usuario ?? {
      nombre: "Invitado",
      rol: "invitado",
    }
  );
});
const menuFinal = computed(() => {
  return JSON.parse(JSON.stringify(menuPorSecciones))
    .map((s) => {
      s.elementos = [...s.elementos]
        .filter(
          (e) =>
            query.value == "" ||
            JSON.stringify(e)
              .toUpperCase()
              .indexOf(query.value.toUpperCase()) >= 0
        )
        .filter((s) => !s.ocultar);
      return s;
    })
    .filter((s) => s.elementos.length > 0)
    .filter((s) => s.roles.indexOf(yo.value.rol) >= 0);
});
const empresa = computed(() => {
  return props.empresa;
});
const salir = () => {
  emit("salir");
};

onMounted(() => {
  console.log({ props });
});
</script>
